import ImageGallery from 'react-image-gallery';

const images = [
    {
        original: '/img/gallery/tako_bannas.jpg',
        description:
            'SilageControl im Einsatz in der Grasernte bei der Firma Blunk. Die Sensorleiste oberhalb der Kabine des Walztreckers bestimmt in Echtzeit das Volumen der Silage.',
    },
    {
        original: '/img/gallery/claas.jpg',
        description:
            'SilageControl kann flexibel an unterschiedlichen Traktoren angebracht und für gängige Silagen eingesetzt werden.',
    },
    {
        original: '/img/gallery/volume_model.png',
        description:
            'Neben der Hardware entwickelt Silolytics auch die Software von SilageControl. Ein Kernelement ist das Volumenmodell, in dem die einsilierte Erntemenge hinterlegt ist.',
    },
    {
        original: '/img/gallery/covered_silage_heap.jpg',
        description:
            'Das Ergebnis ist eine Silage, deren Menge und Qualität ohne manuelle Messverfahren genau bekannt ist.',
    },
    {
        original: '/img/gallery/dried_silage.jpg',
        description:
            'Die Austattung von Entnahmefahrzeugen mit SilageControl ermöglicht weiterhin eine sets aktuelle Reichweitenplanung für die Silage.',
    },
];
export default function Gallery() {
    return (
        <div id="gallery" className="max-w-7xl mx-auto my-20 text-scblue">
            <h2 className="text-center my-10">GALLERIE</h2>
            <div className="max-w-5xl mx-auto">
                <ImageGallery items={images} showBullets={true} showFullscreenButton={false} />
            </div>
        </div>
    );
}
