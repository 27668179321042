import React from 'react';
import '../../../styles/Consortium.css';
import TeamMember from './TeamMember';
import PartnerHeader from './PartnerHeader';
import BackgroundSvg from './BackgroundSvg';

export default class Consortium extends React.Component {
    images = [
        {
            original: '/img/gallery/tako_bannas.jpg',
            description:
                'SilageControl im Einsatz in der Grasernte bei der Firma Blunk. Die Sensorleiste oberhalb der Kabine des Walztreckers bestimmt in Echtzeit das Volumen der Silage.',
        },
        {
            original: '/img/gallery/claas.jpg',
            description:
                'SilageControl kann flexibel an unterschiedlichen Traktoren angebracht und für gängige Silagen eingesetzt werden.',
        },
        {
            original: '/img/gallery/volume_model.png',
            description:
                'Neben der Hardware entwickelt Silolytics auch die Software von SilageControl. Ein Kernelement ist das Volumenmodell, in dem die einsilierte Erntemenge hinterlegt ist.',
        },
        {
            original: '/img/gallery/covered_silage_heap.jpg',
            description:
                'Das Ergebnis ist eine Silage, deren Menge und Qualität ohne manuelle Messverfahren genau bekannt ist.',
        },
        {
            original: '/img/gallery/dried_silage.jpg',
            description:
                'Die Austattung von Entnahmefahrzeugen mit SilageControl ermöglicht weiterhin eine sets aktuelle Reichweitenplanung für die Silage.',
        },
    ];

    silolyticsProfile = {
        name: 'Silolytics GmbH',
        role: 'Projektkoordination',
        svgPosition: 50,
        website: 'https://silolytics.de',
        team: [
            {
                name: 'Ties Junge',
                tasks: 'Hardware und Entwicklung',
                imgSrc: 'img/team/portrait-ties-junge@2x.png',
                email: 't.junge@silolytics.de',
            },
        ],
    };

    cauProfile = {
        name: 'Arbeitsgruppe Software Engineering',
        role: 'Wissenschaftlicher Partner',
        svgPosition: 13,
        website: 'https://www.se.informatik.uni-kiel.de',
        team: [
            {
                name: 'Prof. Dr. Wilhelm Hasselbring',
                tasks: 'Arbeitsgruppenleitung',
                imgSrc: 'img/team/hasselbring.png',
                email: 'hasselbring at email.uni-kiel.de',
            },
        ],
    };

    blunkProfile = {
        name: 'Blunk GmbH',
        role: 'Anwendungspartner',
        svgPosition: 87,
        website: 'https://www.blunk-gmbh.de/',
        team: [
            {
                name: 'Tizian Grigoleit',
                tasks: 'Fachberater Agrar',
                imgSrc: 'img/team/tizian.png',
                email: 't.grigoleit@blunk-gruppe.de',
            },
            {
                name: 'Philipp Staritz',
                tasks: 'Fachberater Agrar',
                imgSrc: 'img/team/staritz.png',
                email: 'p.staritz@blunk-gruppe.de',
            },
        ],
    };

    state = {
        activeProfile: this.cauProfile,
    };

    constructor(props) {
        super(props);
        this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
    }

    handleToggleVisibility(event) {
        console.log(event.target.alt);
        const growDiv = document.getElementById('grow');
        if (!growDiv) return;

        if (growDiv.clientHeight && event.target.alt == this.state.activeProfile.name) {
            growDiv.style.height = 0;
        } else if (growDiv.clientHeight && event.target.alt != this.state.activeProfile.name) {
            switch (event.target.alt) {
                case this.cauProfile.name:
                    this.setState({ activeProfile: this.cauProfile });
                    break;
                case this.silolyticsProfile.name:
                    this.setState({ activeProfile: this.silolyticsProfile });
                    break;
                case this.blunkProfile.name:
                    this.setState({ activeProfile: this.blunkProfile });
                    break;
            }
        } else {
            const wrapper = document.querySelector('.measuringWrapper');
            growDiv.style.height = wrapper.clientHeight + 'px';

            switch (event.target.alt) {
                case this.cauProfile.name:
                    this.setState({ activeProfile: this.cauProfile });
                    break;
                case this.silolyticsProfile.name:
                    this.setState({ activeProfile: this.silolyticsProfile });
                    break;
                case this.blunkProfile.name:
                    this.setState({ activeProfile: this.blunkProfile });
                    break;
            }
        }
    }

    render() {
        return (
            <div id="consortium" className="max-w-7xl mx-10 lg:mx-auto px-4 my-20">
                <h2 className="text-center my-10 text-scblue">PROJEKTKONSORTIUM</h2>

                <div className="flex flex-col items-center md:items-baseline md:flex-row justify-between mb-3 space-y-20 md:space-y-0">
                    <div
                        onClick={this.handleToggleVisibility}
                        className="w-[400px] md:w-[200px] lg:w-[300px] shadow-md h-auto cursor-pointer"
                    >
                        <img className="w-full py-12 px-2" src="img/cau-logo.png" alt={this.cauProfile.name} />
                    </div>
                    <div
                        onClick={this.handleToggleVisibility}
                        className="w-[400px] md:w-[200px] lg:w-[300px] shadow-md h-auto cursor-pointer"
                    >
                        <img
                            className="w-full  pb-12 pt-20 px-2"
                            src="img/silolytics-logo.svg"
                            alt={this.silolyticsProfile.name}
                        />
                    </div>
                    <div
                        onClick={this.handleToggleVisibility}
                        className="w-[400px] md:w-[200px] lg:w-[300px] shadow-md h-auto cursor-pointer"
                    >
                        <img className="w-full" src="img/blunk-logo.jpeg" alt={this.blunkProfile.name} />
                    </div>
                </div>
                <div id="grow" className="grow">
                    <div className="w-=full h-[700px] lg:h-[500px] relative hidden md:block measuringWrapper">
                        <BackgroundSvg topPoint={this.state.activeProfile.svgPosition} />
                        <div className="absolute top-[22%] left-[4%]  w-[92%] h-[70%]">
                            <PartnerHeader
                                name={this.state.activeProfile.name}
                                role={this.state.activeProfile.role}
                                website={this.state.activeProfile.website}
                            />
                            <div className="flex flex-col lg:flex-row justify-around mt-10 space-y-10 lg:space-y-0">
                                {this.state.activeProfile.team.map((member) => (
                                    <TeamMember
                                        key={member.name}
                                        name={member.name}
                                        tasks={member.tasks}
                                        imgSrc={member.imgSrc}
                                        email={member.email}
                                    />
                                ))}
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
