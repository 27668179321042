export default function BackgroundSvg(props) {
    const left = props.topPoint - 5;
    const right = props.topPoint + 5;
    return (
        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path
                d={'M' + left + ',15 0,15 L 0,100 100,100 100,15 ' + right + ',15 ' + props.topPoint + ',0 Z'}
                vectorEffect="non-scaling-stroke"
                stroke="black"
                strokeWidth="0"
                fill="#e4e4e480"
            />
        </svg>
    );
}
