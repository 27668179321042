import { MdOutlineAgriculture, MdOutlineCloudDone } from 'react-icons/md';
import { AiOutlineLineChart } from 'react-icons/ai';

import KeyPoint from './KeyPoint';

export default function Goals() {
    const goals = [
        {
            icon: <MdOutlineAgriculture />,
            title: 'Optimierung der Silierung',
            text: 'Siliert und gelagert werden die Ernteerzeugnisse überwiegend in Fahrsilos. Sensorik und digitale Methoden zur Prozessoptimierung werden aktuell vor allem im Bereich der Erntetechnik, zum Beispiel in Feldhäckslern, eingesetzt. Wenig berücksichtigt wird bislang das zur Verdichtung eingesetzte Walzfahrzeug, mit dem sich für die Silierung und anschließende Verwertung des Erntegutes wichtige Parameter erheben lassen, deren Kenntnis entscheidend für die Vermeidung von Verlusten ist. An diesem Punkt setzt SilageControl an, das als Sensorplattform auf dem Walzfahrzeug montiert wird.',
        },
        {
            icon: <MdOutlineCloudDone />,
            title: 'Landwirtschaft 4.0',
            text: 'Im Rahmen von SilageControl werden modernste IT-Technologien eingesetzt. Mit der Verwendung von internetgestützten Sensoren kommt das Internet der Dinge auf den landwirtschaftlichen Betrieb. Cloud Technologien ermöglichen die skalierbare Datenspeicherung und -auswertung. Der geplante Einsatz von digitalen Zwillingen unterstützt die Softwareentwicklung und gibt Aufschluss über den Stand der Silierung. Die gewonnen Daten helfen den Betrieben zukunftsfähiger zu werden und durch die lückenlose Dokumentation die Transparenz von vorgelagerten Bereichen bis hin zu den Verbrauchern*innen zu gewährleisten.',
        },
        {
            icon: <AiOutlineLineChart />,
            title: 'Bereitstellung von Kennzahlen',
            text: 'Zusätzlich zur Erfassung von Erntemengen wird SilageControl automatisiert Daten zum Silageverbrauch während der Entnahme erfassen. Aus den ermittelten Tagesentnahmen und sich daraus ergebenden Verbräuchen lässt sich die zeitliche Reichweite und damit verbunden die verbleibende Nutzungsdauer bestimmen. Aus Daten kann zudem die Effizienz, basierend auf einer All-in All-out Bilanzierung, errechnet werden, etwa um den CO2-Fußabdruck zuverlässig zu bestimmen.',
        },
    ];

    return (
        <div id="goals" className="max-w-7xl mx-auto px-4 my-20">
            <h2 className="text-center text-scblue">ZIELE</h2>
            <p className="mt-8 mx-auto px-4 max-w-5xl">
                Ein marktreifes System zur Erfassung der Erntemengen, Qualität und Online-Ermittlung der auf den
                landwirtschaftlichen Betrieben vorhanden Silagemengen ist das übergeordnete Ziel des Projektes
                SilageControl. Dieses Ziel hat sich das Projektkonsortium für die Laufzeit des Projektes vom 1. Dezember
                2021 bis 31 Januar 2025 gesetzt. Auf folgenden Punkten liegt dabei ein besonderer Fokus:
            </p>
            <div className="flex flex-col justify-between">
                {goals.map((motivation) => (
                    <KeyPoint
                        key={motivation.title}
                        icon={motivation.icon}
                        title={motivation.title}
                        text={motivation.text}
                    />
                ))}
            </div>
        </div>
    );
}
