import Consortium from './Consortium/Consortium';
import Funding from './Funding';
import Gallery from './Gallery';
import Goals from './Goals';
import HeroSection from './HeroSection';
import Motivation from './Motivation';

export default function LandingPage() {
    return (
        <>
            <HeroSection />
            <Motivation />
            <Goals />
            <Gallery />
            <Consortium />
            <Funding />
        </>
    );
}
