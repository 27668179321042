import { useState } from 'react';

export default function KeyPoint(props) {
    const [showFullText, setShowFullText] = useState(false);

    return (
        <div className="flex flex-col sm:flex-row w-auto p-5 sm:p-5 my-5">
            <div id="keypoint-icon" className="my-auto first:text-[70px] first:text-scgreen flex flex-row">
                {props.icon}
                <h3 className="text-2xl my-auto font-semibold text-scblue sm:hidden ml-5">{props.title}</h3>
            </div>
            <div id="keypoint-content" className="sm:mx-10">
                <div id="keypoint-title" className="mb-10 hidden sm:block">
                    <div className="my-auto">
                        <h3 className="text-2xl my-auto font-semibold text-scblue">{props.title}</h3>
                    </div>
                </div>
                <div id="keypoint-text" className={`${showFullText ? '' : 'line-clamp-5'} lg:line-clamp-none  mt-10`}>
                    {props.text}
                </div>
                <div className="lg:hidden flex justify-end mt-4">
                    <a
                        onClick={() => setShowFullText(!showFullText)}
                        className="text-blue no-underline cursor-pointer select-none"
                    >
                        {showFullText ? 'Weniger anzeigen' : 'Mehr anzeigen'}
                    </a>
                </div>
            </div>
        </div>
    );
}
