export default function TeamMember(props) {
    return (
        <div className="flex flex-row">
            <div className="mr-10">
                <img className="w-44 h-44" src={props.imgSrc} alt={props.name} />
            </div>
            <div>
                <h3 className="font-semibold">{props.name}</h3>
                <p>{props.tasks}</p>
                <a href={'mailto:' + props.email} target="_blank" rel="noreferrer">
                    {props.email}
                </a>
            </div>
        </div>
    );
}
