function Funding() {
    return (
        <div id="funding" className="max-w-7xl mx-auto my-20">
            <h2 className="text-center my-10 text-scblue">FÖRDERER</h2>
            <div className="flex flex-col md:flex-row items-center md:justify-around space-y-10 md:space-y-0 md:items-baseline">
                <div className="w-[400px] md:w-[400px] h-auto">
                    <a href="https://www.bmel.de/" target="_blank" rel="noreferrer">
                        <img className="shadow-md mx-2 w-full" src="img/funding/logo_bmel.png"></img>
                    </a>
                </div>
                <div className="w-[400px] md:w-[300px] h-auto md:mr-32">
                    <a
                        href="https://www.ble.de/DE/Projektfoerderung/Foerderungen-Auftraege/Innovationen/Deutsche-Innovationspartnerschaft-Agrar/deutsche-innovationspartnerschaft-agrar_node.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img className="shadow-md w-full p-2" src="img/funding/dip_logo.jpeg"></img>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Funding;
