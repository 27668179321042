import '../../styles/HeroSection.css';

export default function HeroSection() {
    return (
        <div className="min-h-[600px] h-[90vh] hero-image">
            <div className="max-w-7xl mx-auto h-2/3">
                <div className="max-w-2xl mx-6 h-[full] text-white flex justify-between flex-col items-start">
                    <div className="hero-text">
                        <h1 className="text-3xl sm:text-5xl mt-32">DIE DIGITALISIERUNG DES SILAGEMANAGEMENTS</h1>
                        <div className="text-xl sm:text-2xl my-20">
                            Entwicklung einer intelligenten Sensorplattform zur Erntemengenerfassung- und Management von
                            Silagen
                        </div>
                    </div>
                    <a href="#motivation">
                        <button className="text-sm sm:text-base bg-white p-3 sm:p-3 text-scgreen rounded-xl">
                            MEHR ERFAHREN
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}
