import { MdOutlineGrain, MdOutlineGrass } from 'react-icons/md';
import KeyPoint from './KeyPoint';
import { TbTemperature } from 'react-icons/tb';

export default function Motivation() {
    const motivations = [
        {
            icon: <TbTemperature />,
            title: 'Klima',
            text: 'Wetterextreme, wie ausgeprägte Dürreperioden oder Phasen starker Niederschläge haben in den vergangenen Jahren deutlich zugenommen. Besonders hervorzuheben sind hier die Jahre 2018 und 2019, in denen wenig Futter und Substrat zur Energieerzeugung vorhanden war. Gleichzeitig stammen 7,4% der Treibhausgasemmisionen in Deutschland aus landwirtschaftlicher Produktion. Wie alle Sektoren muss auch die Landwirtschaft das Ziel verfolgen, klimaneutreal zu werden, um die schon bestehenden Auswirkungen des Klimawandels zu begrenzen.',
        },
        {
            icon: <MdOutlineGrass />,
            title: 'Silage',
            text: 'Silage bezeichnet Pflanzenmaterial, das zur ganzjährigen Lagerung unter Abschluss von Luft konserviert wird. Diese werden zur Ernährung von Kühen und zur Energieerzeugung eingesetzt. Um in Zukunft besser mit Klimaextremen umgehen zu können, müssen Silagevorräte hinsichtlich Menge und Qualität genau bekannt sein. Um die Ressourcen möglichst effizient zu nutzen, ist weiterhin die Erfassung der relevanten Qualitätsparameter für den Vorgang der Silierung von entscheidender Bedeutung, um Verluste etwa durch Schimmel zu vermeiden.',
        },
        {
            icon: <MdOutlineGrain />,
            title: 'Düngung',
            text: 'Ein Ziel, das zur Erreichung der Klimaschutzziele 2030 benannt wurde, ist die Senkung der Stickstoffeinträge aus der Landwirtschaft. Um dieses Ziel zu erreichen, ist eine entzugsbasierte Düngung, die sich an realisierten Erträgen der Flächen orientiert, unerlässlich. Hierfür muss zwingend der Ertrag der Futterflächen ermittelt werden. Ein Großteil Betriebe hat keine Technik zur Ertragserfassung, schon gar nicht auf Schlagebene. Eine Düngung, die sich am Entzug orientiert, ist somit nicht möglich. Um die oben genannten Aufgaben zu erfüllen ist es nötig, intelligente und kostengünstige Sensorsysteme zu entwickeln, die einsilierten Mengen und deren Verbrauch sowie im Prozess entstandene Verluste exakt und automatisiert abzubilden und zu dokumentieren.',
        },
    ];

    return (
        <div id="motivation" className="max-w-7xl mx-auto px-4 my-20">
            <h2 className="text-center text-scblue">MOTIVATION</h2>
            <p className="mt-8 max-w-5xl mx-auto">
                Im selben Maße wie andere Produktionsrichtungen steht auch die Landwirtschaft vor der Herausforderung,
                Emissionen zu verringern und die zur Verfügung stehenden Ressourcen effizient zu nutzen. Diese
                Herausforderung stellt die Motivation hinter SilageControl dar.
            </p>
            <div className="flex flex-col justify-between">
                {motivations.map((motivation) => (
                    <KeyPoint
                        key={motivation.title}
                        icon={motivation.icon}
                        title={motivation.title}
                        text={motivation.text}
                    />
                ))}
            </div>
        </div>
    );
}
