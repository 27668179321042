import LinkSymbol from '../../../shared/LinkSymbol';

export default function PartnerHeader(props) {
    return (
        <div className="flex flex-row justify-center">
            <div>
                <h2 className="text-center">{props.name}</h2>
                <p className="text-center">{props.role}</p>
            </div>
            <div className="w-10 h-10 ml-2 mt-2">
                <a href={props.website} target="_blank" rel="noreferrer">
                    <LinkSymbol />
                </a>
            </div>
        </div>
    );
}
